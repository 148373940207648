@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import './variables.css';
@import '~shipmnts_navigation/dist/static/styles/main.css';
@import './report.css';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.header .ant-layout-header {
  border-bottom: 1px solid #f8f9fb;
  background: #fff;
}

/* Sider width controlling, as antd gives fixed */
.sider {
  z-index: 1000;
}

.sider .ant-layout-sider {
  width: 100% !important;
  max-width: none !important;
  min-width: 350 !important;
}

.sider .ant-layout-sider-children {
  height: 100%;
}

/* Nav group style */
.sider .ant-menu-submenu-title {
  color: var(--secondary-black-color);
  font-weight: bold;
  font-size: 10px;
  padding-right: 24px !important;
}

.sider .ant-menu-submenu-title:hover {
  background: none !important;
  cursor: default;
}

.sider .ant-menu-item {
  padding-left: 16px !important;
}

.sider .ant-menu-item-selected {
  border-right: 5px solid var(--bg-primary-black-1);
  font-weight: 600;
}

.sider .ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: inherit;
}

.fixed-header {
  padding: 0;
  background-color: #ffffff !important;
  height: auto;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
}

.subs-and-billing-layout .ant-tabs-nav {
  padding: 0 24px;
  background-color: #ffffff !important;
  height: auto;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
}

.default-bordered-button {
  color: var(--ant-color-primary);
  border: 1px solid var(--ant-color-primary);
}

.justify-between {
  justify-content: space-between;
  align-items: center;
}

.ag-cell {
  align-content: center;
}

.no-margin-form-item .ant-form-item {
  margin-bottom: 0 !important;
}

/* To Globally place close button to the end of header in drawer */
.ant-drawer-header-title {
  flex-direction: row-reverse;
}
.item-label {
  color: var(--ant-color-text-tertiary);
}

.seat_input input {
  text-align: center !important;
}

.transaction_ledger th {
  text-align: center !important;
  color: var(--secondary-black-color) !important;
}
.transaction_ledger td {
  font-size: 13px;
}

.full-width,
.full-width .ant-page-header-content {
  width: 100%;
}
