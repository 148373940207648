:root {
  --ant-color-primary: #141414;
  --ant-color-link: #303030;
  --ant-color-success: #027e46;
  --ant-color-warning: #e86427;
  --ant-color-error: #d91f11;
  --ant-button-font-weight: 500;

  --color-primary: #303030;
  --primary-grey: #303030;
  --secondary-grey-700: #616161;
  --secondary-grey-500: #303030;
  --secondary-black-color: #141414;

  --dark_gray_color: #232a36;
  --secondary_blue_color: #141414;
  --green_color: #027e46;
  --dark_yellow_color: #cc933e;
  --red_color: #d91f11;
  --alert_color: #ffb84e;

  --alternate-action-color: #e94f08;
  --alternate-action-bg-color: #fee2d5;

  --tertiary-action-color: #cc933e;
  --tertiary-action-bg-color: #f0f0f0;

  --link_hover_color: #303030;
  --link_active_color: #616161;
  --link_visited_color: #616161;
  --tag_color: #f0f0f0;
  --tag_border_color: #9fb1bd;
  --tag_hover_color: #e0e0e0;
  --tag_hover_background_color: #e0e0e0;
  --tag_active_background_color: #ffffff;
  --tag_active_color: #616161;
  --tag_active_border_color: #232a36;
  --item-hover-bg: #f0f0f0;
  --component-background: #fff;

  --font-size-base: 14px;
  --secondary-bg-color: #f6f7fa;
}
