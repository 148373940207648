.ag-theme-material .ag-row.ag-row-pinned {
  background-color: #f6f7f7;
}

.ag-theme-material .ag-row.ag-row-group.ag-row-group-expanded {
  font-weight: 500;
  background-color: #e6e5e5b3;
}

.ag-theme-material .ag-ltr .ag-center-aligned-cell .ag-cell-value {
  margin: auto;
}

.ag-theme-material .ag-ltr .ag-center-aligned-cell {
  text-align: center;
}

.ag-theme-material .ag-header-cell,
.ag-theme-material .ag-header-group-cell {
  padding-left: 7px;
  padding-right: 7px;
}

.ag-theme-material .ag-cell:not(.ag-cell-inline-editing),
.ag-theme-material .ag-full-width-row .ag-cell-wrapper.ag-row-group:not(.ag-cell-inline-editing) {
  padding-left: 6px;
  padding-right: 6px;
}

.ag-theme-material .ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open
  .ant-select-selector
  .ant-select-selector-search
  .ant-select-selector-search:focus {
  background-color: rgb(255, 241, 220) !important;
}

.col-bg-cell-focus {
  background-color: rgb(255, 241, 220) !important;
  /* // --ag-selected-row-background-color: rgb(255, 241, 220) !important; */
}

.ag-theme-material .ag-layout-auto-height .ag-cell:not(.ag-cell-inline-editing),
.ag-theme-material
  .ag-layout-auto-height
  .ag-full-width-row
  .ag-cell-wrapper.ag-row-group:not(.ag-cell-inline-editing) {
  display: flex;
  align-items: center;
}

.ag-theme-material .ag-cell.ag-cell-inline-editing,
.ag-theme-material .ag-full-width-row .ag-cell-wrapper.ag-row-group.ag-cell-inline-editing {
  padding: 0px;
}

.ag-theme-material .ag-ltr .ag-cell:not(.ag-cell-range-selected) {
  border-right-color: var(
    --ag-row-border-color,
    var(--ag-secondary-border-color, var(--ag-border-color, #e2e2e2))
  );
}

.ag-theme-material .ag-header-cell,
.ag-theme-material .ag-header-group-cell {
  border-right: 1px solid
    var(--ag-row-border-color, var(--ag-secondary-border-color, var(--ag-border-color, #e2e2e2)));
  border-top: 1px solid
    var(--ag-row-border-color, var(--ag-secondary-border-color, var(--ag-border-color, #e2e2e2)));
}

.ag-header-group-cell-label,
.ag-header-cell-label {
  justify-content: center;
}

.ag-theme-material .ag-cell-inline-editing {
  height: auto;
}

/* Selection checkbox should only show when hovering over the row */
.view-table {
  .ag-row .ag-selection-checkbox {
    align-items: center;
    justify-content: center;
    margin: 0px;
    width: 100%;
  }

  .ag-row .ag-selection-checkbox + span {
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .ag-row:not(.ag-row-selected) .ag-selection-checkbox {
    display: none;
  }

  .ag-row:not(.ag-row-selected) .ag-selection-checkbox + span {
    display: flex;
  }

  .ag-row:not(.ag-row-selected):hover .ag-selection-checkbox {
    display: flex;
  }

  .ag-row:not(.ag-row-selected):hover .ag-selection-checkbox + span {
    display: none;
  }

  .ag-row.ag-row-selected .ag-selection-checkbox + span {
    display: none;
  }
}

.ag-cell {
  align-content: center;
}

.ag-cell-value {
  width: 100%;
}

.ag-theme-material {
  font-family: 'Inter', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
}

.ag-theme-material .ag-header-cell,
.ag-header-group-cell {
  background-color: rgb(242, 242, 242);
}

.ag-grid-header-wrap {
  .ag-header-cell-label .ag-header-cell-text,
  .ag-header-group-cell-label .ag-header-group-text {
    white-space: pre-line !important;
    font-size: 14px;
    color: var(--secondary-black-color);
  }
}
